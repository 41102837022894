export const onInitialClientRender = () => {
    window.loaded = true;
    setTimeout(function () {
        document.getElementById("___splash_1").classList.add("in")
    }, 10)
    setTimeout(function () {
        document.getElementById("___splash_2").classList.add("in")
    }, 1000)
    setTimeout(function () {
        document.getElementById("___splash_2").classList.remove("in")
    }, 4500)
    setTimeout(function () {
        document.getElementById("___splash_1").classList.remove("in")
        document.getElementById("___loader").classList.remove("in")
    }, 6000)

}