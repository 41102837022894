import { extendTheme } from '@chakra-ui/react'
import { withProse } from '@nikolovlazar/chakra-ui-prose'
import { mode } from '@chakra-ui/theme-tools'

const headingStyles = {
    textTransform: 'uppercase',
    letterSpacing: '0.26rem'
}

const theme = {
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },
    colors: {
        black: '#030303',
    },
    styles: {
        global: (props) => ({
            body: {
                bg: mode('white', 'black')(props),
                color: mode('gray.900', 'gray.300')(props),
            },
        }),
    },
    fonts: {
        heading: `'Ampersand', 'Avenir',-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        body: `'Avenir',-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    },
    components: {
        Heading: {
            baseStyle: headingStyles
        },
    }
}

export default extendTheme(theme, withProse({
    baseStyle: {
        h1: headingStyles,
        h2: headingStyles,
        h3: headingStyles,
        h4: headingStyles,
    },
}))