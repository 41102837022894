/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '../utils/utils';
import {
  Flex,
  Input,
  Button,
  Heading,
  Container,
  VStack,
} from '@chakra-ui/react';

const PasswordProtect = () => {
  const [password, setPassword] = useState('');

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <Container height="100vh">
      <Flex height="100%" alignItems="center" justifyContent="center">
        <VStack spacing={4} width="320px">
          <Heading size="md">Enter Password</Heading>
          <form onSubmit={onSubmit} style={{ width: '100%' }}>
            <VStack spacing={4}>
              <Input
                name="password"
                type="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                placeholder="Password"
              />
              <Button type="submit" width="100%">
                Submit
              </Button>
            </VStack>
          </form>
        </VStack>
      </Flex>
    </Container>
  );
};

export default PasswordProtect;
